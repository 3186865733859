import React from "react";
import styles from "./Roadmap.module.scss";
import { useIntl } from "react-intl";
import cl from "classnames";
import line from "src/components/Roadmap/imgs/line.svg";
import arrow from "src/components/Roadmap/imgs/arrow.svg";

const Roadmap = () => {
	const intl = useIntl();

	return (
		<div className={styles.content}>
			<div className={styles.title}>
				{intl.formatMessage({ id: "Neobox Roadmap" })}
			</div>
			<div className={styles.wRoadmap}>
				<div className={styles.column}>
					<div className={cl(styles.block, styles.block1)}>
						<div className={styles.additionalInfo}>Q1 ‘25</div>
						<div className={styles.titleBlock}>
							{intl.formatMessage({ id: "STAGE 1" })}
						</div>
						<div className={cl(styles.subTitleBlock, styles.subTitleBlock1)}>
							{intl.formatMessage({ id: "Launch of Arcade Station" })}
						</div>
						<div className={styles.descriptionBlock}>
							{intl.formatMessage({
								id: " Launching  Arcade Station - a comprehensive platform, which will host a variety of games and applications in addition to existing offerings.  Users will have the opportunity to showcase their games and receive donations in GAS tokens from platform users.",
							})}
						</div>
					</div>
					<img src={arrow} alt={""} className={styles.arrow} />
					<div className={cl(styles.block, styles.block3)}>
						<div className={styles.additionalInfo}>Q3 - Q4 ‘25</div>
						<div className={styles.titleBlock}>
							{intl.formatMessage({ id: "STAGE 3" })}
						</div>
						<div className={cl(styles.subTitleBlock, styles.subTitleBlock3)}>
							{intl.formatMessage({ id: "Phase 2 of Arcade Station" })}
						</div>
						<div className={styles.descriptionBlock}>
							{intl.formatMessage({
								id: "Expansion: Introducing investor-backed projects to Arcade Station, expanding the platform's offerings.",
							})}
						</div>
					</div>
				</div>
				<img src={line} alt={""} className={styles.line} />
				<div className={styles.column}>
					<img src={arrow} alt={""} className={styles.arrow} />
					<div className={cl(styles.block, styles.block2)}>
						<div className={styles.additionalInfo}>Q2 - Q3 ‘25</div>
						<div className={styles.titleBlock}>
							{intl.formatMessage({ id: "STAGE 2" })}
						</div>
						<div className={cl(styles.subTitleBlock, styles.subTitleBlock2)}>
							{intl.formatMessage({ id: "Private Sale" })}
						</div>
						<div className={styles.descriptionBlock}>
							{intl.formatMessage({
								id: "Initiating a Private Sale phase to negotiate with potential investors, which is expected to significantly boost the token launch.",
							})}
						</div>
					</div>
					<img src={arrow} alt={""} className={styles.arrow} />
					<div className={cl(styles.block, styles.block4)}>
						<div className={styles.additionalInfo}>Q4 ‘25</div>
						<div className={styles.titleBlock}>
							{intl.formatMessage({ id: "STAGE 4" })}
						</div>
						<div className={cl(styles.subTitleBlock, styles.subTitleBlock4)}>
							{intl.formatMessage({ id: "Official Token Launch" })}
						</div>
						<div className={styles.descriptionBlock}>
							{intl.formatMessage({
								id: "Officially launching the token, marking the culmination of the phased development and expansion efforts.",
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Roadmap;
